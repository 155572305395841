import UserInfo from '@/models/users/UserInfo';
import { UserRoleEnum } from '@/enums/UserRoleEnum';

export default class UserParticipant extends UserInfo {
  private _isParticipant: boolean;

  constructor(
    uid: string,
    fullName: string,
    roles: UserRoleEnum[],
    firstName: string,
    lastName: string,
    email: string,
    matriculationNumber: string,
    isParticipant: boolean
  ) {
    super(uid, fullName, roles, firstName, lastName, email, matriculationNumber);
    this._isParticipant = isParticipant;
  }

  get isParticipant(): boolean {
    return this._isParticipant;
  }

  set isParticipant(value: boolean) {
    this._isParticipant = value;
  }
}
