











































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { PossibleAction } from '@/auth/PossibleAction';
import { hasPermission } from '@/auth/AuthService';
import Experiment from '@/models/experiment/Experiment';
import ExperimentService from '@/services/ExperimentService';
import UserInfo from '@/models/users/UserInfo';
import UserService from '@/services/UserService';
import UserParticipant from '@/models/experiment/UserParticipant';

@Component
export default class AddParticipantsToExperiment extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;
  protected loadedExperiment: Experiment = new Experiment('', '', '', '', [], []);
  protected allUsers: UserInfo[] = [];
  protected participantUsers: UserParticipant[] = [];
  protected id = -1;

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  mounted(): void {
    this.id = parseInt(this.$route.params.id);
    if (hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION)) {
      this.setLoading(true);
      ExperimentService.getExperimentById(this.id)
        .then((experiment) => {
          this.setLoading(false);
          this.loadedExperiment = experiment;
          this.getAllUsers();
        })
        .catch((backendError) => {
          this.setLoading(false);
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast(
              'Failed to load',
              'Could not load experiment. ' + backendError.response.data.message,
              'danger'
            );
          }
        });
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  protected cancel(): void {
    this.$router.push({ path: '/experiment/admin/edit/' + this.loadedExperiment.id });
  }

  protected getAllUsers(): void {
    this.setLoading(true);
    UserService.getAllUserInfos()
      .then((result) => {
        this.allUsers = result;
        this.participantUsers = [];
        for (const u of this.allUsers) {
          if (this.loadedExperiment.participants.includes(u.uid)) {
            const user = new UserParticipant(
              u.uid,
              u.fullName,
              u.roles,
              u.firstName,
              u.lastName,
              u.email,
              u.matriculationNumber,
              true
            );
            this.participantUsers.push(user);
          } else {
            const user = new UserParticipant(
              u.uid,
              u.fullName,
              u.roles,
              u.firstName,
              u.lastName,
              u.email,
              u.matriculationNumber,
              false
            );
            this.participantUsers.push(user);
          }
        }
        this.setLoading(false);
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not users. ' + backendError.response.data.message, 'danger');
        }
      });
  }

  protected saveExperiment(): void {
    this.setLoading(true);
    const participantsNew: string[] = [];
    for (const u of this.participantUsers) {
      if (u.isParticipant) {
        participantsNew.push(u.uid);
      }
    }
    this.loadedExperiment.participants = participantsNew;
    ExperimentService.updateExperimentParticipants(this.loadedExperiment)
      .then(() => {
        this.setLoading(false);
        this.cancel();
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to save', 'Could not add experiment. ' + backendError.response.data.message, 'danger');
        }
      });
  }
}
